var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Radio group options array" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeArray) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _c("code", [_vm._v("options")]),
        _c("span", [
          _vm._v(" can be an array of strings or objects. Available fields: ")
        ]),
        _c("code", [_vm._v("value")]),
        _c("span", [_vm._v(" The selected value which will be set on ")]),
        _c("code", [_vm._v("v-model")]),
        _c("span", [_vm._v(", ")]),
        _c("code", [_vm._v("disabled")]),
        _c("span", [_vm._v(" Disables item for selection, ")]),
        _c("code", [_vm._v("text")]),
        _c("span", [_vm._v(" Display text, or ")]),
        _c("code", [_vm._v("html")]),
        _c("span", [_vm._v(" Display basic inline html")])
      ]),
      _c("b-form-radio-group", {
        staticClass: "demo-inline-spacing mb-1",
        attrs: {
          options: _vm.options,
          "value-field": "value",
          "text-field": "text",
          "disabled-field": "disabled"
        },
        model: {
          value: _vm.selected,
          callback: function($$v) {
            _vm.selected = $$v
          },
          expression: "selected"
        }
      }),
      _c("b-card-text", { staticClass: "mt-1 mb-0" }, [
        _vm._v(" Selected: "),
        _c("strong", [_vm._v(_vm._s(_vm.selected))])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }